/*!
 *  - index.js
 * @generated Thu Mar 28 2024 09:42:56 GMT+0900 (GMT+09:00)
 */
'use strict';

$(window).on('load', function () {
	$('.index-mainSlide').addClass('is-active');
	$('.index-mainSlide').slick({
		autoplay: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		loop: true,
		arrows: true,
		prevArrow: '<a class="slick-prev" href="#"><i class="icon icon-arrow-left"></i></a>',
		nextArrow: '<a class="slick-next" href="#"><i class="icon icon-arrow-right"></i></a>',
		dots: true,
		centerMode: true,
		infinite: true,
		variableWidth: true,
		responsive: [{
			breakpoint: 1280,
			settings: {
				centerMode: false,
				variableWidth: false
			}
		}]
	});
});